import { defineStore } from "pinia";
// import api from "@/api";

export const useDefaultStore = defineStore("main", () => {
	const quoteFeatures = ref<number[]>([]);
	const quoteDatePickerVal = ref("");
	const quoteSelectedDesign = ref("not_selected");
	const quoteName = ref<string | null>("");
	const quoteLastName = ref("");
	const quoteEmail = ref<string | null>("");
	const quotePhone = ref<string | null>("");
	const quoteComment = ref("");
	const userReferrer = ref<string | null>(null);
	const cookieConsent = ref(false);
	const priceImpactFeatures = ref(0);
	const priceImpact = ref("low");
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const isOpenChatBox = ref(false);
	const userIP = ref<string | null>(null);
	const userCity = ref<string | null>(null);
	const userCountryCode = ref<string | null>(null);
	const availBool = ref(0);

	const leadsDomain = "442c123.mars-t.mars-hosting.com"; // dev
	// const leadsDomain = "435t123.mars2.mars-hosting.com"; // prod
	const leadsRootApi = `https://${leadsDomain}/api/`;

	function resetForm() {
		quoteFeatures.value = [];
		quoteDatePickerVal.value = "";
		quoteSelectedDesign.value = "not_selected";
		quoteName.value = null;
		quoteLastName.value = "";
		quoteEmail.value = null;
		quotePhone.value = null;
		quoteComment.value = "";
		priceImpact.value = "low";
	}

	const isFormValid = computed(() => {
		if (!quoteEmail.value && !quotePhone.value) return false;
		if (quoteEmail.value) {
			const isEmailValid = emailRegex.test(quoteEmail.value.trim());
			return isEmailValid;
		} else {
			return true;
		}
	});

	function trackQuoteCTA() {
		// Track the CTA click
		// fbq("trackCustom", "ClickGetQuote");
	}

	async function getIp() {
		try {
			const res = await axios.get(`${leadsRootApi}users/get-ip`);
			userIP.value = res.data.ip;
			if (userIP.value) await getLoaction(userIP.value);
		} catch (e: any) {
			console.error(e);
		}
	}

	async function getLoaction(ip: string) {
		const accessToken = "94c8294a2a11db";
		try {
			const response = await axios.get(
				`https://ipinfo.io/${ip}?token=${accessToken}`,
			);
			userCity.value = response.data.city;
			userCountryCode.value = response.data.country;
		} catch (error) {
			console.error(error);
		}
	}

	async function getConfig() {
		const params = {
			from: "user",
		};

		try {
			const res = await axios.get(
				`${leadsRootApi}configurations/configurations-get`,
				{ params },
			);
			// const res = await api.configGet(params);
			availBool.value = res.data.data[0].is_active;
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}

	async function notificationSound(sound: any) {
		try {
			await sound.play();
		} catch (error) {
			console.error("Failed to play notification sound:", error);
		}
	}

	return {
		emailRegex,
		quoteFeatures,
		quoteDatePickerVal,
		quoteSelectedDesign,
		quoteName,
		quoteLastName,
		quoteEmail,
		quotePhone,
		quoteComment,
		resetForm,
		isFormValid,
		userReferrer,
		cookieConsent,
		priceImpact,
		priceImpactFeatures,
		trackQuoteCTA,
		leadsDomain,
		leadsRootApi,
		isOpenChatBox,
		userIP,
		userCity,
		userCountryCode,
		getIp,
		getConfig,
		availBool,
		notificationSound,
	};
});
