<template>
	<teleport to="body">
		<div class="cookies-modal-bg">
			<div class="cookies-modal-wrapper">
				<h3>Cookies</h3>
				<p class="cookies-modal-text">
					We use cookies to collect and analyse information on site performance
					and usage, to provide social media features and to enhance and
					customise content and advertisements.
				</p>
				<div class="btn-wrapper">
					<button @click="acceptCookies">Accept</button>
					<p class="cookies-modal-reject" @click="declineCookies">Reject</p>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script setup>
import { useDefaultStore } from "@/store";

const store = useDefaultStore();

const acceptCookies = () => {
	localStorage.setItem("cookieConsent", "true");
	store.cookieConsent = true;
	grantConsent();
};

const declineCookies = () => {
	localStorage.setItem("cookieConsent", "false");
	store.cookieConsent = true;
};

watch(store.cookieConsent, () => {
	store.cookieConsent = localStorage.getItem("cookieConsent") !== null;
});

function grantConsent() {
	// fbq("consent", "grant");
}

onMounted(() => {
	// fbq("consent", "revoke");
	store.cookieConsent = localStorage.getItem("cookieConsent") !== null;
	if (store.cookieConsent) {
		store.cookieConsent = true;
		const isAccepted = localStorage.getItem("cookieConsent") === "true";
		if (isAccepted.value) grantConsent();
	}
});
</script>

<style lang="scss" scoped>
.cookies-modal-bg {
	background-color: rgba(0, 0, 0, 0.555);
	height: 100%;
	width: 100%;
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	animation: appear2 0.8s forwards;

	.cookies-modal-wrapper {
		max-width: 350px;
		width: 90%;
		background-color: white;
		color: black;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 24px;

		h3 {
			align-self: flex-start;
			margin-bottom: 12px;
			font-size: 20px;
			user-select: none;
		}

		.cookies-modal-text {
			margin-bottom: 24px;
		}

		.btn-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			button {
				padding: 10px 20px;
				border: none;
				border-radius: 5px;
				background-color: $second-app-color;
				color: white;
				cursor: pointer;

				&:hover {
					opacity: 0.9;
				}
			}

			.cookies-modal-reject {
				font-size: 11px;
				cursor: pointer;
				opacity: 0.45;

				&:hover {
					text-decoration: underline;
					opacity: 1;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.cookies-modal-bg {
		.cookies-modal-wrapper {
			transform: unset;
			top: calc(100vh - 100px - 289px);
			right: 100px;
			left: unset;
		}
	}
}
</style>
